import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Footer, Nav } from "../../../context/imports";
import { FaBookOpen, FaClipboardCheck, FaChalkboardTeacher, FaBriefcase } from 'react-icons/fa'; // Import icons

const Test = () => {

  const Blogcard = () => {
    return <>

    </>
  }

  const getNews = () => {

  }
  return (
    <>
      <Nav />
      <Wrapper className="container">
        <div className="row">
          <div className="blogs" style={{ width: "25%" }}>
            { }
          </div>
          <div className="cards" style={{ width: "50%" }}>
            <Card title="Materials" link="/materials" icon={<FaBookOpen />} />
            <Card title="Previous Papers" link="/previous-papers" icon={<FaClipboardCheck />} />
            <Card title="Results" link="/results" icon={<FaChalkboardTeacher />} />
            <Card title="Mentors" link="/techies" icon={<FaChalkboardTeacher />} />
            <Card title="Placement Materials" link="/placement-materials" icon={<FaBookOpen />} />
            <Card title="Placement Offers" link="/dashboard/user/y20placements" icon={<FaBriefcase />} />
          </div>
          <div className="recent" style={{ width: "25%" }}>
            {/* You can add recent activity content here */}
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

// Card component to handle individual cards
const Card = ({ title, link, icon }) => {
  return (
    <CardWrapper className="card">
      <IconWrapper>{icon}</IconWrapper>
      <h4>{title}</h4>
      <Link to={link}>
        <div className='btn btn-outline-primary btn-sm'>Visit Page</div>
      </Link>
    </CardWrapper>
  );
};

// Styled component for Wrapper
const Wrapper = styled.div`
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

// Styled component for Card
const CardWrapper = styled.div`
  width: 50%;
  background-color: #f0f8ff; // Light background for cards
  padding: 10px;
  margin: 10px auto; // Center cards with auto margins
  border-radius: 10px; // More rounded corners
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); // Enhanced shadow for depth
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;

  h4 {
    margin: 10px 0; // Margin around title
    font-weight: bold; // Bold font for title
    color: #333; // Darker text color for readability
  }

  &:hover {
    transform: translateY(-5px); // Lift effect
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); // Darker shadow on hover
  }
`;

// Styled component for IconWrapper
const IconWrapper = styled.div`
  font-size: 2em; // Larger icons
  color: #007bff; // Primary color for icons
  margin-bottom: 10px; // Space between icon and title
`;

// Styled component for Button
const Button = styled.button`
  background-color: #007bff; // Bootstrap primary color
  color: white;
  border: none;
  padding: 10px 15px; // Padding for a better button size
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9em; // Slightly larger font size
  margin-top: 10px; // Space above the button

  &:hover {
    background-color: #0056b3; // Darker shade on hover
    transform: scale(1.05); // Slightly enlarge on hover
  }
`;

export default Test;
