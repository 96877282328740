import React, { useEffect } from "react";
import Footer from "../../components/Reusable/js/Footer";
import { Toaster } from "react-hot-toast";
import styles from "./css/Homepage.module.css"
import Nav from "../../components/Reusable/js/Nav";
import  {LatestNews, Posts } from "../../components/SideComponents/js/LatestNews";
import SiteNews from "../../components/SideComponents/js/SiteNews";
import { HomepageServices } from "../../components/SideComponents/js/HomepageServices";
import axios from "axios";


const Homepage = () => {

  const login = async () => {
    const email = "";
    const password = "";
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/login`, { email, password });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    login();
  }, [])


  return (
    <>
      <Nav />
      <Toaster />
      <div className="container" style={{ display: "flex", flexDirection: "column" }}>


        <div className={`container ${styles.content}`}>

          <div className={`m-2 ${styles.latestnews}`}>

            <LatestNews />
          </div>

          <div className={`${styles.middle}`}>
            <div className="container" style={{ overflowY: "auto", marginTop: "20px" }}>
              <HomepageServices />
            </div>
          </div>

          <div className={`${styles.sitenews} m-2`} >
            <Posts />
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
