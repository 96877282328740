import React, { useEffect, useState } from 'react'
import Nav from '../../components/Reusable/js/Nav'
import Footer from '../../components/Reusable/js/Footer'
import styles from "./css/AdminProfile.module.css"
import axios from 'axios'
import toast from 'react-hot-toast'// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";

import { Button, Checkbox, Col, Dropdown, Menu, Row, Space } from 'antd';
import { database, firebaseConfig } from '../../context/imports'

const AdminProfile = () => {

    const [container, setContainer] = useState("")


    const AddPlacementMaterial = () => {
        const [title, settitle] = useState("");
        const [tag, settag] = useState([])
        const [des, setdes] = useState("")
        const [link, setlink] = useState("")
        const [dlink, setdlink] = useState("")
        const [imglink, setimglink] = useState(" ")
        const [date, setdate] = useState(" ")

        const tags = [
            'android', 'aptitude', 'ds', 'dbms', 'dsa',
            'fsd', 'gate', 'ml', 'coding', 'python', 'sd',
            'resources', 'interview', 'tips', 'startups', 'projects', 'companies'
        ];


        const onChange = (checkedValues) => {
            settag(checkedValues)
            console.log('checked = ', checkedValues);
        };

        const addplacement = async () => {
            try {
                const datar = { title, tag, des, link, dlink, imglink, date }
                const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/pages/addplamaterial`, datar)
                if (res.data.success) {
                    toast.success(res.data.message)
                    settitle("")
                    settag([])
                    setdes("")
                    setlink("")
                    setdlink("")
                    setimglink("")
                    setdate("")
                }
                else {
                    toast.error(res.data.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
        return (
            <>
                <div className="form text-center">
                    <h1>Add placement material</h1>
                    <div className='m-2'>
                        <input type="text" value={title} className='p-1' onChange={(e) => settitle(e.target.value)} placeholder='enter title of material' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>

                    <div className='m-2'>
                        <input type="text" value={des} className='p-1' onChange={(e) => setdes(e.target.value)} placeholder='enter des of material' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={link} className='p-1' onChange={(e) => setlink(e.target.value)} placeholder='enter link of material' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={dlink} className='p-1' onChange={(e) => setdlink(e.target.value)} placeholder='enter download link of material' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className="m-2">

                        <Checkbox.Group
                            style={{
                                width: '100%',
                            }}
                            onChange={onChange}
                        >
                            <Row style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                                {tags.map((tag, index) => (
                                    <Col span={8} key={index}>
                                        <h3><Checkbox value={tag}>{tag}</Checkbox></h3>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>

                    </div>

                    <button className='btn btn-primary btn-sm m-2' onClick={addplacement}>Submit</button>
                </div>
            </>
        )
    }

    const AddHostel = () => {
        const [title, settitle] = useState("");
        const [tag, settag] = useState("")
        const [des, setdes] = useState("")
        const [link, setlink] = useState("")
        const [dlink, setdlink] = useState("")
        const [imglink, setimglink] = useState(" ")
        const [date, setdate] = useState(" ")

        const addhostel = async () => {
            try {
                const datar = { title, tag, des, link, dlink, imglink, date }
                const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/pages/addplamaterial`, datar)
                if (res.data.success) {
                    toast.success(res.data.message)
                }
                else {
                    toast.error(res.data.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
        return (
            <>
                <div className="form text-center">
                    <h1>Add placement material</h1>
                    <div className='m-2'>
                        <input type="text" value={title} className='p-1' onChange={(e) => settitle(e.target.value)} placeholder='enter title of hostel' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={tag} className='p-1' onChange={(e) => settag(e.target.value)} placeholder='enter tag of hostel' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={des} className='p-1' onChange={(e) => setdes(e.target.value)} placeholder='enter des of hostel' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={link} className='p-1' onChange={(e) => setlink(e.target.value)} placeholder='enter link of hostel' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <div className='m-2'>
                        <input type="text" value={dlink} className='p-1' onChange={(e) => setdlink(e.target.value)} placeholder='enter download link of hostel' style={{ borderRadius: "7px", minWidth: "20rem" }} />
                    </div>
                    <button className='btn btn-primary btn-sm m-2' onClick={addhostel}>Submit</button>
                </div>
            </>
        )
    }


    const AddStudentPlacement = () => {

        const [name, setname] = useState("")
        const [regno, setregno] = useState("")
        const [count, setcount] = useState("")
        const [sal, setsal] = useState("")

        const handlesubmit = async () => {
            try {
                const datar = { name, regno, count, sal }
                const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/admin/add-student-placement`, datar)
                if (res.data.success) {
                    toast.success("submitted done!")
                    setname("")
                    setcount("")
                    setregno("")
                    setsal("")
                }
                else {
                    toast.error("failed to post")
                }
            } catch (error) {
                console.log(error)
            }
        }

        return (
            <>

                <div className="container" style={{ minHeight: "50vh", display: "flex", flexDirection: "row" }}>

                    <div >
                        <div className="form-group m-2">
                            <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" placeholder="Enter student name" />
                        </div>

                        <div className="form-group m-2">
                            <input type="text" value={regno.toLowerCase()} onChange={(e) => setregno((e.target.value).toLowerCase())} className="form-control" placeholder="regno" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={count} onChange={(e) => setcount(e.target.value)} className="form-control" placeholder="count" />
                        </div>
                        <div className="form-group m-2">
                            <input type="text" value={sal} onChange={(e) => setsal(e.target.value)} className="form-control" placeholder="sal" />
                        </div>

                        <div className="form-group m-2">
                            <button className='btn btn-sm btn-primary' onClick={() => handlesubmit()}>submit</button>
                        </div>
                    </div>
                </div>

            </>
        )
    }



    const AddNews =  () => {
        const [title, setTitle] = useState("");
        const [des, setDes] = useState("");
        const [date, setDate] = useState("");
        const [link, setLink] = useState("");
    
        const handleSubmit = async () => {
            try {
                const app = initializeApp(firebaseConfig);
                const db = getFirestore(app);
    
                const data = {
                    title,
                    description: des, // Ensure keys match what you want to store
                    date,
                    link,
                    time:new Date()
                };
    
                // Specify the collection to add data
                await addDoc(collection(db, 'becstuoweb/Homepage/News'), data);
    
                toast.success("Submission successful!");
                // Clear the form fields
                setTitle("");
                setDes("");
                setDate("");
                setLink("");
            } catch (error) {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            }
        };
    
        return (
            <div className="container" style={{ minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "400px", padding: "20px", backgroundColor: "#ffffff", borderRadius: "10px", boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" }}>
                    <h3 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>Add News like Placements</h3>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            placeholder="Enter title"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={des}
                            onChange={(e) => setDes(e.target.value)} // Corrected here
                            className="form-control"
                            placeholder="Description"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="form-control"
                            placeholder="Date"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            className="form-control"
                            placeholder="Link"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div
                        className='btn btn-primary btn-sm'
                        onClick={handleSubmit}
                        style={{ padding: "10px", borderRadius: "5px", fontSize: "16px", cursor: "pointer" }}
                    >
                        Submit
                    </div>
                </div>
            </div>
        );
    };

    const Addrecentpost =  () => {
        const [title, setTitle] = useState("");
        const [des, setDes] = useState("");
        const [date, setDate] = useState("");
        const [link, setLink] = useState("");
    
        const handleSubmit = async () => {
            try {
                const app = initializeApp(firebaseConfig);
                const db = getFirestore(app);
    
                const data = {
                    title,
                    description: des, // Ensure keys match what you want to store
                    date,
                    link,
                    time:new Date()
                };
    
                // Specify the collection to add data
                await addDoc(collection(db, 'becstuoweb/Homepage/Posts'), data);
    
                toast.success("Submission successful!");
                // Clear the form fields
                setTitle("");
                setDes("");
                setDate("");
                setLink("");
            } catch (error) {
                console.error(error);
                toast.error("An error occurred. Please try again.");
            }
        };
    
        return (
            <div className="container" style={{ minHeight: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "400px", padding: "20px", backgroundColor: "#ffffff", borderRadius: "10px", boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" }}>
                    <h3 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>Add News like recent posts</h3>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            placeholder="Enter title"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={des}
                            onChange={(e) => setDes(e.target.value)} // Corrected here
                            className="form-control"
                            placeholder="Description"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="form-control"
                            placeholder="Date"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div className="form-group">
                        <input
                            type="text"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            className="form-control"
                            placeholder="Link"
                            style={{ marginBottom: "15px", padding: "10px", borderRadius: "5px", border: "1px solid #ced4da" }}
                        />
                    </div>
    
                    <div
                        className='btn btn-primary btn-sm'
                        onClick={handleSubmit}
                        style={{ padding: "10px", borderRadius: "5px", fontSize: "16px", cursor: "pointer" }}
                    >
                        Submit
                    </div>
                </div>
            </div>
        );
    };



    const GetMessagesiTkonnects = () => {

        // Initialize Firebase outside of the component to avoid re-initializing on every render
        const app = initializeApp(firebaseConfig);

        const db = getFirestore(app);
        const [messages, setMessages] = useState([]); // State to store messages

        useEffect(() => {
            const fetchMessages = async () => {
                try {
                    // Get the collection reference and fetch documents
                    const querySnapshot = await getDocs(collection(db, "/writes/contact/writesomething"));
                    const fetchedMessages = [];
                    console.log("request started messages")

                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        fetchedMessages.push({
                            email: data.email,
                            message: data.mes,
                            time: data.time,
                            timestamp: data.timestamp,
                            userId: data.rid,
                        });
                    });

                    setMessages(fetchedMessages.reverse()); // Update state with fetched messages
                } catch (error) {
                    toast.error("failed to fetch")
                    console.error("Error retrieving documents: ", error);
                }
            };

            fetchMessages();
        }, []); // Empty dependency array to run only once when the component mounts

        return (
            <div>
                <h2>Messages</h2>
                <div style={{
                    display: "flex",
                    flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"
                }}>


                    {messages.length > 0 ? (
                        messages.map((msg, index) => (
                            <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', width: "auto", minWidth: "40%" }}>
                                <p><strong>Email:</strong> {msg.email}</p>
                                <p style={{ textWrap: "wrap" }}><strong style={{ textWrap: "wrap" }}>Message:</strong> {msg.message}</p>
                                <p><strong>Time:</strong> {msg.time}</p>

                                {/* <p><strong>Timestamp:</strong> {msg.timestamp}</p>
                            <p><strong>User ID:</strong> {msg.userId}</p> */}
                            </div>
                        ))
                    ) : (
                        <p>No messages found.</p>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="fixed">
                <Nav />
                <div className="container" style={{ minHeight: "50vh", display: "flex", flexDirection: "row" }}>
                    <div className={`${styles.mobileadminprofile}`}>
                        <div className="list-group">
                            <button className="btn btn-success btn-sm m-1" onClick={() => { setContainer("") }}>Admin Menu</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("addpla") }}>Add placement material</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("") }}>Add Hostel</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("addstupla") }}>Add student placement</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("messages") }}>Messages</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("") }}>Menu</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("addnews") }}>Add News</button>
                            <button className="btn btn-primary btn-sm m-1" onClick={() => { setContainer("addrecentpost") }}>Add recent</button>
                        </div>
                    </div>
                    <div style={{ width: "70%" }}>
                        {container === "" && <><h1>Admin dashboard for operations</h1></>}
                        {container === "addpla" && <AddPlacementMaterial />}
                        {container === "addstupla" && <AddStudentPlacement />}
                        {container === "messages" && <GetMessagesiTkonnects />}
                        {container === "addnews" && <AddNews />}
                        {container === "addrecentpost" && <Addrecentpost />}

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AdminProfile