import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firebaseConfig } from '../../../context/imports';

export const LatestNews = () => {

    const [news, setNews] = useState("")
    

    //get news from firebase db 
    //path=/becstuoweb/Homepage/News

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    // Fetch news from Firestore
    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'becstuoweb/Homepage/News');

            // Use orderBy to sort by time in descending order
            const newsQuery = query(newsCollection, orderBy('time', 'desc'));

            const newsSnapshot = await getDocs(newsQuery);
            const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Extract data and add id
            setNews(newsList);
        } catch (error) {
            console.error("Error fetching news: ", error);
        }
    };

   

    useEffect(() => {
        fetchNews();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <>

            {news && news.slice(0, 10).map(item => (
                <div className='card m-2'>
                    <h6 style={{ margin: "1px", padding: "1px" }}>{item.title}</h6>
                    <p style={{ margin: "0", padding: "0" }}>{item.description}</p>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                        <span style={{ fontSize: "0.8rem", margin: "0", padding: "0" }} className='px-1'>{item.date}</span>

                        <a href={item.link ? item.link : ""} target='_blank' className="btn btn-sm btn-outline-primary fa-solid fa-arrow-right" style={{ textDecoration: "none" }}></a>
                    </div>
                </div>
            ))}

        </>
    )
}


export const Posts=()=>{
    const [posts , setPosts ] = useState("")

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const fetchPosts = async () => {
        try {
            const postCollection = collection(db, 'becstuoweb/Homepage/Posts');

            // Use orderBy to sort by time in descending order
            const postQuery = query(postCollection, orderBy('time', 'desc'));

            const postSnapshot = await getDocs(postQuery);
            const postList = postSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Extract data and add id
            setPosts(postList);
        } catch (error) {
            console.error("Error fetching news: ", error);
        }
    };


    useEffect(() => {
        fetchPosts();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <>

            {posts && posts.slice(0, 10).map(item => (
                <div className='card m-2'>
                    <h6 style={{ margin: "1px", padding: "1px" }}>{item.title}</h6>
                    <p style={{ margin: "0", padding: "0" }}>{item.description}</p>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                        <span style={{ fontSize: "0.8rem", margin: "0", padding: "0" }} className='px-1'>{item.date}</span>

                        <a href={item.link ? item.link : ""} target='_blank' className="btn btn-sm btn-outline-primary fa-solid fa-arrow-right" style={{ textDecoration: "none" }}></a>
                    </div>
                </div>
            ))}

        </>
    )

}

