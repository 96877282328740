import React, { useContext } from "react";
import Footer from "../../../../components/Reusable/js/Footer";
import AuthContext from "../../../../context/AuthContext";
import AdminMenu from "../../../../components/admin/js/AdminMenu";
import Nav from "../../../../components/Reusable/js/Nav";
import { FaUser, FaEnvelope, FaPhone, FaUniversity, FaShieldAlt } from "react-icons/fa"; // Import necessary FontAwesome icons



const Profile = () => {
    const [auth] = useContext(AuthContext);
    const isadmin = auth?.user?.role === "0";

    const Profilecard = () => {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                <div className="card shadow-sm" style={{ width: "30%", borderRadius: "10px", padding: "20px" }}>
                    <div className="card-body">
                        <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
                            <FaUser className="me-2" /> Profile
                        </h2>
                        <hr />

                        <div className="d-flex justify-content-center">
                            <div className="profile-info" style={{ width: "70%" }}>
                                <p className="profile-detail">
                                    <FaUser className="me-2 text-primary" />
                                    <strong>Name:</strong> {auth?.user.name}
                                </p>
                                <p className="profile-detail">
                                    <FaUniversity className="me-2 text-warning" />
                                    <strong>Reg No:</strong> {auth?.user.regno}
                                </p>
                                <p className="profile-detail">
                                    <FaEnvelope className="me-2 text-danger" />
                                    <strong>Email:</strong> {auth?.user.email}
                                </p>
                                <p className="profile-detail">
                                    <FaPhone className="me-2 text-success" />
                                    <strong>Phone:</strong> {auth?.user.phone}
                                </p>
                                <p className="profile-detail">
                                    <FaShieldAlt className="me-2 text-info" />
                                    <strong>Security Answer:</strong> {auth?.user.answer}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

return (
    <>
        <Nav />

        {
            isadmin ? <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-3">
                        {isadmin && <AdminMenu />}
                    </div>

                    <div className="col-lg-9">
                        <div className="card shadow-sm" style={{ borderRadius: "10px", padding: "20px" }}>
                            <div className="card-body">
                                <h2 className="text-center mb-4" style={{ fontWeight: "bold" }}>
                                    <FaUser className="me-2" /> Profile
                                </h2>
                                <hr />

                                <div className="d-flex justify-content-center">
                                    <div className="profile-info" style={{ width: "70%" }}>
                                        <p className="profile-detail">
                                            <FaUser className="me-2 text-primary" />
                                            <strong>Name:</strong> {auth?.user.name}
                                        </p>
                                        <p className="profile-detail">
                                            <FaUniversity className="me-2 text-warning" />
                                            <strong>Reg No:</strong> {auth?.user.regno}
                                        </p>
                                        <p className="profile-detail">
                                            <FaEnvelope className="me-2 text-danger" />
                                            <strong>Email:</strong> {auth?.user.email}
                                        </p>
                                        <p className="profile-detail">
                                            <FaPhone className="me-2 text-success" />
                                            <strong>Phone:</strong> {auth?.user.phone}
                                        </p>
                                        <p className="profile-detail">
                                            <FaShieldAlt className="me-2 text-info" />
                                            <strong>Security Answer:</strong> {auth?.user.answer}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <Profilecard />
        }



        <Footer />
    </>
);
};

export default Profile;
